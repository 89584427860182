<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <!-- brand logo -->
            <router-link to="/" class="brand-logo d-flex align-center">
                <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>
                <v-img src="@/assets/text.svg" max-height="25px" alt="logo" position="center left" contain></v-img>
            </router-link>
            <!--/ brand logo -->

            <v-row class="auth-row ma-0">
                <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper">
                        <!-- triangle bg -->
                        <img height="362" class="auth-mask-bg" :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)" />

                        <!-- tree -->
                        <!-- <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img> -->

                        <!-- 3d character -->
                        <div class="d-flex align-center h-full pa-16 pe-0">
                            <v-img contain max-width="100%" height="692" class="auth-3d-group" :src="require(`@/assets/images/team.svg`)"></v-img>
                        </div>
                    </div>
                </v-col>

                <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
                    <v-row>
                        <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
                            <transition :name="appRouteTransition" mode="out-in" appear>
                                <router-view></router-view>
                            </transition>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    // eslint-disable-next-line object-curly-newline
    import themeConfig from '@themeConfig';
    import useAppConfig from '@core/@app-config/useAppConfig';

    export default {
        setup() {
            const { appRouteTransition } = useAppConfig();
            return {
                // themeConfig
                appName: themeConfig.app.name,
                appLogo: themeConfig.app.logo,
                appRouteTransition,
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@core/preset/preset/pages/auth.scss';
</style>
